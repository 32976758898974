<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-alert v-if="value.locationId" outlined type="info" text>
        The names and addresses of Locations included in the Common Destinations Table should be edited in the Locations
        Table.
      </v-alert>

      <v-form :readonly="value.id > 0 && !canEditSetupTables">
        <v-expansion-panels accordion mandatory>
          <v-expansion-panel>
            <v-expansion-panel-header>General</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-combobox
                    :value="value.category"
                    v-model.trim="$v.form.category.$model"
                    :items="destinationCategories"
                    label="Category"
                    required
                    :error-messages="handleErrors($v.form.category)"
                    @blur="$v.form.category.$touch()"
                    @input.native="$v.form.category.$model = $event.srcElement.value"
                    :readonly="value.readonly || value.locationId > 0"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="7">
                  <v-text-field
                    :value="value.name"
                    v-model.trim="$v.form.name.$model"
                    label="Name"
                    required
                    :error-messages="handleErrors($v.form.name)"
                    @blur="$v.form.name.$touch()"
                    :readonly="value.readonly || value.locationId > 0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-checkbox
                    :value="value.active"
                    v-model="$v.form.active.$model"
                    label="Active"
                    required
                    @change="$v.form.active.$touch()"
                    :readonly="value.readonly || value.locationId > 0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    :value="value.email"
                    v-model.trim="$v.form.email.$model"
                    label="Email"
                    required
                    @blur="$v.form.email.$touch()"
                    :readonly="value.readonly"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <phone-number
                    :value="value.phone"
                    v-model.trim="$v.form.phone.$model"
                    label="Phone"
                    required
                    @blur="$v.form.phone.$touch()"
                    :readonly="value.readonly"
                  ></phone-number>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Address</v-expansion-panel-header>

            <v-expansion-panel-content>
              <destination-autocomplete
                :loading="loadingDestinations"
                :includeDestinations="false"
                :searchInput="autocompleteSearchText"
                @destinationChanged="handleNewDestination"
                @internalLoading="(val) => (loadingDestinations = val)"
                hint="Use this field to prefill the form below, or complete the form manually"
                outlined
                persistent-hint
                v-model="id"
              >
                <template #item="{ on, attrs, item }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-icon color="orange" left>mdi-map-marker-plus</v-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </template>
              </destination-autocomplete>

              <v-divider class="my-6"></v-divider>

              <v-row class="pt-2">
                <address-form
                  :multiple="false"
                  :showLatLong="true"
                  ref="aform"
                  required
                  v-model="$v.form.address.$model"
                ></address-form>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Directions</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    label="Directions"
                    :value="value.directions"
                    v-model.trim="$v.form.directions.$model"
                    @blur="$v.form.directions.$touch()"
                    :readonly="value.readonly"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-show="value.id">
            <v-expansion-panel-header>Certificate Liability Attachment</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="12" v-if="!hasAttachments">
                  <upload-file
                    ref="uploadFile"
                    recordType="destination"
                    :recordId="value.id"
                    showSize
                    :hint="!value.id ? 'Save the destination before adding an attachment' : ''"
                    :persistentHint="!value.id"
                    :readonly="!value.id"
                    @onUpload="onUpload"
                  ></upload-file>
                </v-col>

                <v-col cols="12" md="12" v-show="hasAttachments">
                  <attachments
                    ref="attachments"
                    recordType="destination"
                    :recordId="value.id"
                    @onFiles="handleOnFiles"
                  ></attachments>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <date-picker
                    label="Certificate Expiration Date"
                    :value="value.liabilityExp"
                    v-model="$v.form.liabilityExp.$model"
                    @blur="$v.form.liabilityExp.$touch()"
                    :readonly="value.readonly"
                  ></date-picker>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { handleErrors } from '@/util';

import AddressForm from '@/components/AddressForm.vue';
import AddressFormValidations from '@/models/AddressFormValidation.model';
import Attachments from '@/components/Attachments.vue';
import DatePicker from '@/components/DatePicker.vue';
import DestinationAutocomplete from '@/components/DestinationAutocomplete.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import UploadFile from '@/components/UploadFile.vue';

const newDestination = {
  name: '',
  category: '',
  active: true,
  address: {},
  email: '',
  phone: '',
  directions: '',
  liabilityExp: '',
};

export default {
  mixins: [validationMixin],
  components: { AddressForm, DestinationAutocomplete, PhoneNumber, DatePicker, UploadFile, Attachments },
  props: {
    value: {
      type: Object,
      default: () => newDestination,
    },
  },
  data: () => ({
    autocompleteSearchText: '',
    form: newDestination,
    handleErrors,
    hasAttachments: false,
    id: null,
    loadingDestinations: false,
    saved: false,
  }),
  validations: {
    form: {
      active: {},
      address: AddressFormValidations,
      category: { required },
      directions: {},
      email: {},
      liabilityExp: {},
      name: { required },
      phone: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('destination', ['destinationCategories']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;

      const changesMade = this.compareValues();

      if (!fromSave && changesMade) {
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      }

      if (ok) {
        this.form = newDestination;

        if (this.$refs.aform) this.$refs.aform.clear();

        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');

        return;
      }

      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    handleNewDestination({ address: destinationAddress }) {
      if (!destinationAddress) return;

      this.form.address = destinationAddress;
    },
    handleOnFiles(files) {
      this.hasAttachments = !!files.length;
    },
    onUpload() {
      this.$refs.attachments.fetchItems();
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.category || this.$v.form.$model.name)) return true;

      if (!this.id) return false;

      if (
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.category != this.value.category ||
        this.$v.form.$model.active != this.value.active ||
        JSON.stringify(this.$v.form.address.$model) != JSON.stringify(this.value.address) ||
        this.$v.form.$model.email != this.value.email ||
        this.$v.form.$model.phone != this.value.phone ||
        this.$v.form.$model.directions != this.value.directions ||
        this.$v.form.$model.liabilityExp != this.value.liabilityExp
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value = {}) {
        this.id = value.id;

        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.autocompleteSearchText = '';
          this.form = { ...newDestination, ...value, address: { ...(value.address ? value.address : {}) } };
        }
      },
    },
  },
};
</script>

<style scoped>
.file-chip {
  max-width: 90%;
}
.file-name {
  max-width: 99%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
